import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  Box,
  Dialog,
  Typography,
  Divider,
  DialogContent,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import * as api from "application/storage.api";
import { Carousel, Image } from "view/shared/components";
import { INTRO_TIMEOUT, INWIN_PORTAL_SIGNUP_URL } from "config";

import { useStyles } from "./styles";

const items = [
  {
    image: "circle",
    description:
      "InWin is the platform for fans to affect change through the sports and athletes they love.",
  },
  {
    image: "basketball_score",
    ext: "gif" as const,
    description: "Donate for every win!",
  },
  {
    image: "football",
    ext: "gif" as const,
    description: "Donate for every shutout!",
  },
  {
    image: "tenis_ball",
    ext: "gif" as const,
    description: "Donate for every goal, triple-double, touchdown, or homerun!",
  },
];

export const Intro = () => {
  const [opened, openDialog] = useState(false);
  const styles = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const onFinishIntro = useCallback(() => {
    api.markIntroAsSeen();
  }, []);

  const closeDialog = useCallback(() => {
    onFinishIntro();
    openDialog(false);
  }, [onFinishIntro]);

  useEffect(() => {
    if (!api.isIntroSeen()) {
      setTimeout(() => {
        openDialog(true);
      }, 1000);
    }
  }, []);

  const slides = useMemo(() => {
    const slides = items.map((item) => (
      <Box key={item.description} className={styles.slide}>
        <Box className={styles.slideContent}>
          {item.image && (
            <figure className={styles.figure}>
              <Image
                ns="intro"
                name={item.image}
                ext={item.ext}
                className={styles.image}
              />
            </figure>
          )}
          <Box p={2}>
            <Divider />
          </Box>
          <Typography variant="h5">{item.description}</Typography>
        </Box>
      </Box>
    ));

    slides.push(
      <Box className={styles.slide}>
        <div>
          <Typography variant="h5">
            By placing an InWin, you can contribute to charities championed by
            sporting stars or sponsor a club, team, or athlete trying to make
            their dream come true.
          </Typography>
          <Box py={2}>
            <Divider />
          </Box>
          <Button
            variant="outlined"
            color="primary"
            href={INWIN_PORTAL_SIGNUP_URL}
          >
            Pre-Register
          </Button>
        </div>
      </Box>
    );

    return slides;
  }, [styles]);

  return (
    <Dialog open={opened} fullScreen={small}>
      <div className={styles.dialogHeader}>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <Box p={2}>
          <Carousel
            slides={slides}
            timeout={INTRO_TIMEOUT}
            onFinish={onFinishIntro}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
