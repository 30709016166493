import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogHeader: {
      display: "flex",
      justifyContent: "flex-end",
    },

    figure: {
      [theme.breakpoints.up("sm")]: {
        minWidth: theme.spacing(62),
      },
      width: "100%",
      height: theme.spacing(34),
      margin: 0,
    },

    image: {
      maxHeight: "100%",
      [theme.breakpoints.down("xs")]: {
        width: "inherit",
      },
    },

    slide: {
      display: "flex",
      alignItems: "center",

      minHeight: theme.spacing(40),
      textAlign: "center",
    },

    slideContent: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);
