import React, { useEffect } from "react";
import { LocationProvider } from "@reach/router";
import { ThemeProvider, CssBaseline, Theme } from "@material-ui/core";

import { history } from "history";

import { Intro } from "./components/intro";
import { Main } from "./components/main";
import { loadAxios } from "../application/axios";
import { TouchDialog, TouchDialogProvider } from "./shared/components";

const WaveAnimation = () => (
  <div className="ocean">
    <div className="wave"></div>
    <div className="wave"></div>
  </div>
);

loadAxios();

export const Application = ({ theme }: { theme: Theme }) => {
  useEffect(
    () =>
      history.listen(() => {
        window.scrollTo(0, 0);
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <LocationProvider history={history}>
        <TouchDialogProvider>
          <TouchDialog />
          <div id="rootbar"></div>
          <CssBaseline />
          <WaveAnimation />
          <Intro />
          <Main />
        </TouchDialogProvider>
      </LocationProvider>
    </ThemeProvider>
  );
};
