import { STORAGE_KEYS } from "config";

const storage = localStorage;

export const markIntroAsSeen = () => {
  storage.setItem(STORAGE_KEYS.INTRO, "seen");
};

export const isIntroSeen = () => {
  const introStatus = storage.getItem(STORAGE_KEYS.INTRO);
  return introStatus === "seen";
};
