import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Grid, Typography } from "@material-ui/core";
import * as api from "application/statistics.api";
import { useStyles } from "./styles";
import {
  SponsorCard,
  TournamentCard,
  BeneficiaryCard,
} from "../../shared/components";
export const Beneficiaries = (props: RouteComponentProps) => {
  const styles = useStyles();
  const [nbaGames, setNBAStatistic] = useState({
    seasonMatches: 0,
    allMatches: 0,
  });
  const [nhlGames, setNHLStatistic] = useState({
    seasonMatches: 0,
    allMatches: 0,
  });
  interface Beneficiaries {
    id: number;
    logo: string;
    href: string;
    name: string;
  }
  const [beneficiaries, setBeneficiaries] = useState([] as Beneficiaries[]);
  const [sponsor, setSponsor] = useState({});
  const memoBeneficiaries = useMemo(
    () => beneficiaries.filter((_, index) => index < 3),
    [beneficiaries]
  );
  useEffect(() => {
    api.getStatisticTournament("nba").then(setNBAStatistic);
    api.getStatisticTournament("nhl").then(setNHLStatistic);
    api.getStatisticBeneficiaries().then(setBeneficiaries);
    api.getStatisticSponsors().then(setSponsor);
  }, []);

  return (
    <>
      <Box textAlign="center" className={styles.root}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant={"h5"}>Tournaments</Typography>
          </Grid>
          <TournamentCard
            type={"NBA"}
            seasonGames={nbaGames.seasonMatches}
            allGames={nbaGames.allMatches}
          />
          <TournamentCard
            type={"NHL"}
            seasonGames={nhlGames.seasonMatches}
            allGames={nhlGames.allMatches}
          />
        </Grid>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant={"h5"}>Beneficiaries</Typography>
          </Grid>
          {memoBeneficiaries.map((beneficiary) => (
            <BeneficiaryCard key={beneficiary.id} beneficiary={beneficiary} />
          ))}
          <BeneficiaryCard isPlaceholder beneficiary={{}} />
        </Grid>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant={"h5"}>Sponsors</Typography>
          </Grid>
          <SponsorCard sponsor={sponsor} isPlaceholder={false} />
          <SponsorCard sponsor={sponsor} isPlaceholder={true} />
        </Grid>
      </Box>
    </>
  );
};
