import React, {
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { Box, Fade, IconButton } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeftOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { ICarouselProps } from "./types/ICarouselProps";
import { useStyles } from "./styles";

export const Carousel = ({ slides, timeout, onFinish }: ICarouselProps) => {
  const styles = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const finishStatusRef = useRef(false);
  const timeoutIdRef = useRef(-1);

  const stopActiveTimer = () => {
    if (timeoutIdRef.current !== -1) {
      window.clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = -1;
    }
  };

  const toSlide = (index: number) => () => {
    setActiveIndex(index);
  };

  const moveForward = useCallback(() => {
    if (activeIndex < slides.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  }, [slides, activeIndex]);

  const moveBack = useCallback(() => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  }, [activeIndex]);

  useLayoutEffect(() => {
    const move = (): number => {
      return window.setTimeout(() => {
        setActiveIndex((activeIndex) => {
          if (activeIndex < slides.length - 1 && !finishStatusRef.current) {
            timeoutIdRef.current = move();

            return activeIndex + 1;
          }

          return activeIndex;
        });
      }, timeout);
    };

    stopActiveTimer();
    timeoutIdRef.current = move();

    return stopActiveTimer;
  }, [activeIndex, slides, timeout]);

  useEffect(() => {
    const lastSlide = activeIndex === slides.length - 1;
    const isFinished = finishStatusRef.current;

    if (lastSlide) {
      finishStatusRef.current = true;
    }

    if (lastSlide && !isFinished) {
      onFinish();
    }
  }, [activeIndex, slides, onFinish]);

  return (
    <Box>
      <Fade key={activeIndex} in={Boolean(slides[activeIndex])} timeout={1000}>
        {slides[activeIndex]}
      </Fade>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton className={styles.navButton} onClick={moveBack}>
          <ArrowLeftIcon />
        </IconButton>
        <Box>
          {slides.map((_item, index) => (
            <FiberManualRecordIcon
              key={index}
              fontSize="small"
              color={index === activeIndex ? "primary" : "secondary"}
              className={styles.indicator}
              onClick={toSlide(index)}
            />
          ))}
        </Box>
        <IconButton className={styles.navButton} onClick={moveForward}>
          <ArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
